<template>
  <v-container class="my-5" style="height: 100%">
    <v-row justify="center" align="center" style="height: 100%">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          width="150px"
          style="margin-left: auto; margin-right: auto" />
      </v-col>

      <v-col xl="3" lg="5" md="6" sm="9" xs="12">
        <validation-observer v-slot="{ handleSubmit, failed }">
          <v-form
            lazy-validation
            @submit.prevent="handleSubmit(submitForm)"
            style="display: flex; flex-direction: column">
            <validation-provider rules="required|max:200" v-slot="{ errors }" name="username">
              <v-text-field
                v-model="username"
                clearable
                :label="$t('login.username')"
                :error-messages="errors"></v-text-field>
            </validation-provider>

            <validation-provider rules="required|max:200" v-slot="{ errors }" name="password">
              <v-text-field
                v-model="password"
                clearable
                :label="$t('login.password')"
                type="password"
                :error-messages="errors"></v-text-field>
            </validation-provider>

            <v-checkbox v-model="rememberMe" :label="$t('login.rememberMe')"></v-checkbox>

            <button-submit :failed="failed" :loading="loading" buttonText="buttons.login"></button-submit>

            <v-dialog v-model="errorModalValue" max-width="500">
              <v-card>
                <v-card-text>
                  <br />
                  {{ $t('login.passwordChangeError') }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark @click="errorModalValue = false">
                    {{ $t('buttons.ok') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import state from '../store/index';
import router, { rolesMap } from '../router/index';
import store from '@/store';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'LoginView',
  props: ['passwordChangeError'],
  components: { ButtonSubmit },
  data: () => ({
    username: '',
    password: '',
    rememberMe: true,
    user: null,
    loading: false,
    errorModalValue: null,
  }),
  created() {
    this.errorModalValue = this.passwordChangeError;
  },
  methods: {
    submitForm() {
      this.loading = true;
      this.$store
        .dispatch('auth/login', {
          username: this.username,
          email: this.username,
          password: this.password,
          remember_me: this.rememberMe,
        })
        .then((res) => {
          if (res.success) {
            if (res.user.role == 'masterAdmin') {
              res.user.role_id = 1;
            } else if (res.user.role == 'websiteAdmin') {
              res.user.role_id = 2;
            } else if (res.user.role == 'orderAdmin') {
              res.user.role_id = 3;
            }
            state.dispatch('auth/setUser', res.user);
            state.dispatch('auth/setRole', res.user);
            state.dispatch('auth/setAuthToken', res.token);
            state.dispatch('auth/setAuthenticated', true);

            const userRole = rolesMap.find((e) => e.id === parseInt(store.getters['auth/role']));
            if (this.$deviceToken) {
              // eslint-disable-next-line no-console
              this.submitToken(this.$deviceToken).catch(() => console.log('error saving token'));
            }
            router.push({ name: userRole.redirect }).catch((err) => {
              // Ignore the vuex err regarding  navigating to the page they are already on.
              if (
                err.name !== 'NavigationDuplicated' &&
                !err.message.includes('Avoided redundant navigation to current location')
              ) {
                // But print any other errors to the console
                // eslint-disable-next-line no-console
                console.log(err);
              }
            });
            router.push({ name: 'HomeCalendarView' });
            localStorage.setItem('loggedIn', true);
            if (this.rememberMe === true) {
              localStorage.setItem('GreenWaveToken', res.token);
              localStorage.setItem('GreenWaveUser', JSON.stringify(res.user));
              localStorage.setItem('saveToken', true);
            }
          }
          // } else {
          //   if (res.code === 'LE-001') {
          //     this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.loginFailed'), color: 'red' });
          //   } else if (res.code) {
          //     this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.organisationSuspended'), color: 'red' });
          //   } else {
          //     this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          //   }
          // }
        })
        .catch((e) => {
          if (e.response.data.code === 'LE-001') {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.loginFailed'), color: 'red' });
          } else if (e.response.data.code) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.organisationSuspended'), color: 'red' });
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async submitToken(token) {
      await this.$store.dispatch('firebaseToken/submitToken', { token });
    },
  },
};
</script>
